// 质检记录
<template>
  <div>

    <div class="flex-nowrap-flex-start">
      <div class="first-table">
        <div>
          <el-form ref="form" :model="form" label-position="left" label-width="120px">
            <el-form-item label="质检总开关">
              <el-switch v-model="form.optionValue" active-color="#13ce66" inactive-color="#ff4949"
                         active-text="已开启" inactive-text="未开启" active-value="1" inactive-value="0"
                         @click="confirm(1)">
              </el-switch>
            </el-form-item>
          </el-form>

          <el-form ref="form" :model="vosRateForm" label-position="left" label-width="120px">
            <el-form-item label="质检比例">
              <el-select v-model="vosRateForm.optionValue" placeholder="请选择" style="width: 30%"
                         clearable filterable
                         @change="changeVosRate">
                <el-option v-for="(item, index) in vosRateList" :key="index"
                           :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span class="md-title-medium m3-margin-left-m">%</span>
            </el-form-item>
          </el-form>

          <el-form ref="form" :model="vosChannelForm" label-position="left" label-width="120px">
            <el-form-item label="质检声道">
              <el-select v-model="vosChannelForm.optionValue" placeholder="请选择" style="width: 30%"
                         clearable filterable
                         @change="changeVosChannel">
                <el-option v-for="(item, index) in vosChannelList" :key="index"
                           :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <el-form ref="form" :model="vosShortForm" label-position="left" label-width="120px">
            <el-form-item label="质检时长">
              <span class="md-title-medium m3-margin-right-m">低于</span>
              <el-select v-model="vosShortForm.optionValue" placeholder="请选择" style="width: 13%"
                         clearable filterable
                         @change="changeVosShort">
                <el-option v-for="(item, index) in vosShortList" :key="index"
                           :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span class="md-title-medium m3-margin-left-m">秒不质检</span>
            </el-form-item>
          </el-form>


          <el-form ref="firstForm" :inline="true" size="mini" :model="searchForm.firstForm"
                   label-position="center" label-width="60px">
            <el-form-item label="用户名" v-if="userType == 'system'">
              <div class="div-width">
                <el-select v-model="searchForm.firstForm.uid" placeholder="请选择" style="width: 100%"
                           clearable filterable>
                  <el-option v-for="(item, index) in user_list" :key="index"
                             :label="`${item.nickname}(Id:${item.uid})`" :value="item.uid">
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="IP查询">
              <div class="div-width">
                <el-input v-model="searchForm.firstForm.ip" clearable placeholder="IP查询"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="开关">
              <div class="div-width">
                <el-select v-model="searchForm.firstForm.status" placeholder="状态">
                  <el-option label="开启" :value="0"></el-option>
                  <el-option label="关闭" :value="1"></el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="getFirstData(searchForm.firstForm)">查询</el-button>
              <el-button type="primary" v-if="userType == 'system'" @click="addUser">添加客户端</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-table :data="tableData.firstTableData.records" stripe v-loading="loadingFirst" tooltip-effect="dark"
                    style="width: 100%" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" size="mini"
                    height="57vh" @row-click="firstRowClick"
                    :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }">
            <el-table-column prop="nickName" label="用户名" v-if="userType == 'system'">
            </el-table-column>
            <el-table-column prop="id" label="ID">
            </el-table-column>
            <el-table-column prop="ip" label="IP">
            </el-table-column>
            <el-table-column label="状态">
              <template #default="scope">
                <div v-if="scope.row.onLine == 1" class="online flex-nowrap-flex-start">
                  <div class="online-icon line-icon"></div>
                  <div class="yes-green">在线</div>
                </div>
                <div v-else class="offline flex-nowrap-flex-start">
                  <div class="offline-icon line-icon"></div>
                  <div class="offLine-color">离线</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="开关">


              <template #default="scope">
                <el-switch size="mini" v-model="scope.row.status" active-color="#13ce66"
                           inactive-color="#ff4949" :active-value="0" :inactive-value="1"
                           @click="confirm(2, scope.row)">
                </el-switch>
              </template>

            </el-table-column>


            <el-table-column label="质检时长设置">

              <template #default="scope">
                <div class="flex">
                  <div style="width:30px">
                    {{ scope.row.interceptDuration == '0' ? '全检' : scope.row.interceptDuration + 's' }}

                  </div>

                  <div style="cursor:pointer" class="xiugai" @click="setup(scope.row)">
                    修改
                  </div>
                </div>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <div class="flex-nowrap-flex-end">
          <el-pagination @size-change="handleSizeChange_first" @current-change="handleCurrentChange_first"
                         :current-page="currentPage1" :page-sizes="[10, 20,50,100]"
                         :page-size="searchForm.firstForm.pageSize"
                         layout="total, sizes, prev, pager, next, jumper" :total="tableData.firstTableData.total">
          </el-pagination>
        </div>
      </div>
      <div class="second-table">
        <div>
          <div class="second-table_top">
            <span>主叫质检开关</span>
            <div class="second-table_top_button">
              <el-button :loading="callerStartLoading" @click="callerStart" size="mini"
                         type="primary">全部开启
              </el-button>
              <el-button :loading="callerCloseLoading" @click="callerClose" size="mini"
                         type="danger">全部关闭
              </el-button>
              <el-button :loading="batchOpenCallerLoading" @click="batchOpenCaller" size="mini" type="primary">
                批量开启
              </el-button>
              <el-button :loading="batchCloseCallerLoading" @click="batchCloseCaller" size="mini" type="danger">
                批量关闭
              </el-button>
            </div>
          </div>
          <el-form ref="secondForm" :inline="true" size="mini" :model="searchForm.secondForm"
                   label-position="center" label-width="80px">

            <el-form-item label="主叫前缀">
              <div class="div-width">
                <el-input v-model="searchForm.secondForm.telAPrefix" placeholder="主叫前缀"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="行业">
              <div class="div-width">
                <el-input v-model="searchForm.secondForm.telBPrefix" placeholder="行业"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="用户" v-if="userType == 'system'">
              <div class="div-width">
                <el-select v-model="searchForm.secondForm.uid" placeholder="请选择" style="width: 100%"
                           clearable filterable>
                  <el-option v-for="(item, index) in user_list" :key="index"
                             :label="`${item.nickname}(Id:${item.uid})`" :value="item.uid">
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="创建时间">
              <!-- <div class="div-width"> -->
              <el-date-picker v-model="searchForm.secondForm.createTimeArr" type="datetimerange"
                              value-format="YYYY-MM-DD HH:mm:ss" :shortcuts="shortcuts" range-separator="-"
                              start-placeholder="开始" end-placeholder="结束"/>
              <!-- </div> -->
            </el-form-item>
            <el-form-item label="状态">
              <div class="div-width">
                <el-select v-model="searchForm.secondForm.status" placeholder="状态">
                  <el-option label="开启" :value="0"></el-option>
                  <el-option label="关闭" :value="1"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="secondSearch">查询</el-button>
              <el-button type="primary" @click="addConfig">添加</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-table @selection-change="handleSelectionChange" v-loading="loadingSecond"
                    :data="tableData.secondTableData.records" stripe tooltip-effect="dark" style="width: 100%"
                    :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" size="mini" height="57vh"
                    :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }">
            <el-table-column align="center" type="selection" width="55px">
            </el-table-column>
            <el-table-column prop="telAPrefix" label="主叫前缀">
              <template #default="scope">
                <span>{{ scope.row.telAPrefix ? scope.row?.telAPrefix : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="telBPrefix" label="被叫前缀">
              <template #default="scope">
                <span>{{ scope.row.telBPrefix ? scope.row?.telBPrefix : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="industry" label="行业">
            </el-table-column>
            <el-table-column prop="nickName" label="用户名" v-if="userType == 'system'" min-width="150px"
                             align="center">
            </el-table-column>
            <el-table-column label="质检比例">
              <template #default="scope">
                <el-input v-model="scope.row.chance" placeholder="输入100以内的正整数" size="mini"
                          onkeyup="value=value.replace(/[^\d||/.]/g,'')"
                          oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=0} if(value>100){value=100}"
                          :maxlength="3" :minlength="1" @change="upText(scope.row)">
                  <template #suffix>%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建日期">
            </el-table-column>
            <el-table-column label="开关">
              <template #default="scope">
                <div class="flex-wrap-flex-start">
                  <div>
                    <el-switch size="mini" v-model="scope.row.status" active-color="#13ce66"
                               inactive-color="#ff4949" :inactive-value="1" :active-value="0"
                               @click="confirm(3, scope.row)">
                    </el-switch>
                  </div>
                  <div :class="scope.row.status == 1 ? 'close-font' : 'open-font'">
                    {{ scope.row.status == 0 ? '已开启' : '已关闭' }}
                  </div>
                </div>

              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <span class="button" @click="editorConfig(scope.row)">编辑</span>
                <span class="button" @click="deleteConfig(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex-nowrap-flex-end">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="currentPage2" :page-sizes="[10, 20,50,100]"
                         :page-size="searchForm.secondForm.pageSize"
                         layout="total, sizes, prev, pager, next, jumper" :total="tableData.secondTableData.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div>
      <el-dialog :title="dialogTitle" v-model="dialogVisible" v-loading="addUserData" :close-on-click-modal="false"
                 :destroy-on-close="true" width="30%">
        <el-form v-if="dialogTitle == '质检时长设置'" :model="ruleForm3" size="mini" :rules="rules" ref="ruleForm3"
                 label-width="100px" class="demo-ruleForm">

          <el-form-item label-width="150px" label="质检时长（秒）：" prop="interceptDuration">

            <el-input placeholder="请输入需要质检的录音时长" v-model="ruleForm3.interceptDuration"></el-input>
            <div>
              质检时长必须大于等于60S，如果录音文件为120秒，质检时长设置100秒，只质检100秒内的录音。
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button @click="resetForm('ruleForm3')">取消</el-button>
              <el-button type="primary" @click="submitForm('ruleForm3')">保存</el-button>
            </div>
          </el-form-item>
        </el-form>


        <el-form v-if="dialogTitle == '新增用户'" :model="ruleForm1" size="mini" :rules="rules" ref="ruleForm1"
                 label-width="100px" class="demo-ruleForm">
          <el-form-item label="用&ensp;&ensp;户：" prop="uid">
            <el-select v-model="ruleForm1.uid" placeholder="请选择" style="width: 100%" clearable filterable>
              <el-option v-for="(item, index) in user_list" :key="index"
                         :label="`${item.nickname}(Id:${item.uid})`" :value="item.uid">
                <div class="flex-nowrap-space-between">
                  <div :class="item.status == 1 ? 'no-red' : ''">
                    <span>{{ item.nickname }}</span>
                    <span class="ml-5">(Id:{{ item.uid }})</span>
                  </div>
                  <div v-if="item.status == 1" class="no-red">禁</div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="IP" prop="ip">
            <el-input v-model="ruleForm1.ip"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button @click="resetForm('ruleForm1')">取消</el-button>
              <el-button type="primary" @click="submitForm('ruleForm1')">保存</el-button>
            </div>
          </el-form-item>
        </el-form>
        <el-form v-if="dialogTitle == '新增配置' || dialogTitle == '编辑配置'" :model="ruleForm2" size="mini"
                 :rules="rules"
                 ref="ruleForm2" label-width="100px" class="demo-ruleForm">
          <el-form-item label="主叫前缀">
            <el-input v-model="ruleForm2.telAPrefix"></el-input>
          </el-form-item>
          <el-form-item label="被叫前缀">
            <el-input v-model="ruleForm2.telBPrefix"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">

            <el-select v-model="ruleForm2.status" style="width: 100%" placeholder="">
              <el-option label="开启" :value="0"></el-option>
              <el-option label="关闭" :value="1"></el-option>
            </el-select>

          </el-form-item>
          <el-form-item label="行业" prop="industry">
            <el-input v-model="ruleForm2.industry"></el-input>
          </el-form-item>
          <el-form-item label="用户" v-if="userType == 'system'">
            <el-select v-model="ruleForm2.uid" placeholder="请选择" style="width: 100%" clearable filterable>
              <el-option v-for="(item, index) in user_list" :key="index"
                         :label="`${item.nickname}(Id:${item.uid})`" :value="item.uid">
                <div class="flex-nowrap-space-between">
                  <div :class="item.status == 1 ? 'no-red' : ''">
                    <span>{{ item.nickname }}</span>
                    <span class="ml-5">(Id:{{ item.uid }})</span>
                  </div>
                  <div v-if="item.status == 1" class="no-red">禁</div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div>
              主叫前缀、被叫前缀最少要填写一个。
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button @click="resetForm('ruleForm2')">取消</el-button>
              <el-button type="primary" @click="submitForm('ruleForm2')">保存</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  IconFont
} from '@/utils/iconfont'
import {
  ElMessage
} from 'element-plus'
import voiceCheck from '@/api/open/voice/voiceCheck'
import userts from "@/api/web/user";
import {message} from 'ant-design-vue';
import EmptyUtil from "@/utils/common/EmptyUtil";

export default {
  components: {
    IconFont,
  },
  data() {
    return {
      batchOpenCallerLoading: false,
      batchCloseCallerLoading: false,
      selectIds: [],
      callerStartLoading: false,
      callerCloseLoading: false,
      userType: this.$store.state.userType,
      shortcuts: [{
        text: '近一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      }, {
        text: '近一月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      }, {
        text: '近三个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },],
      form: {},
      vosRateForm: {},
      vosChannelForm: {},
      vosShortForm: {},
      loadingFirst: true, //主表loading
      loadingSecond: true, //子表
      searchForm: {
        firstForm: { //主表查询条件
          "page": 1,
          "pageSize": 10,
          "uid": '',
          "ip": "",
          "status": 0
        },
        secondForm: {
          "page": 1,
          "pageSize": 10,
          "uid": "",
          "clientId": '',
          createTimeArr: [],
          "status": 0
        }
      },
      tableData: {
        firstTableData: { //主表数据
          records: [],
          total: 0,
          size: 0,
        },
        secondTableData: {
          records: [],
          total: 0,
          size: 0,
        }
      },
      vosRateList: [], //比例（取值0-100，数字）
      vosChannelList: [
        {
          value: '-1',
          label: '全部',
        }, {
          value: '0',
          label: '只质检被叫声道',
        }, {
          value: '1',
          label: '只质检主叫声道',
        },
      ], //声道 （-1是全部 0-只质检被叫声道 1-只质检主叫声道，下拉）
      vosShortList: [], //时长 （低于N秒不质检，数字0-60）
      user_list: [], //用户列表
      currentPage1: 1, //主表页码
      currentPage2: 1, //子表页码
      dialogVisible: false, //弹窗显示
      addUserData: false, //添加loading
      dialogTitle: '', //弹窗名称
      ruleForm1: { //主表添加
        status: 0
      },
      ruleForm2: {
        chance: 100,
        status: ''

      }, //子表添加
      ruleForm3: {
        interceptDuration: '',
        id: '',
        ip: ''

      }, //子表添加
      createTimeArr: [],
      vosShortListtime:[
   {
        value: '120',
        label: '两分钟',
      },
      //三分钟
      {
        value: '180',
        label: '三分钟',
      },
    //四分钟
      {
        value: '240',
        label: '四分钟',
      },
      //五分钟
      {
        value: '300',
        label: '五分钟',
      },
      //六分钟
      {
        value: '360',
        label: '六分钟',
      },
      //七分钟
      {
        value: '420',
        label: '七分钟',
      },
      //八分钟
      {
        value: '480',
        label: '八分钟',
      },
      //九分钟
      {
        value: '540',
        label: '九分钟',
      },  //十分钟
      {
        value: '600',
        label: '十分钟',
      },
      ],
      rules: { //校验规则


        interceptDuration: [


          {
            required: true,
            message: '请输入质检时长',
            trigger: 'change'
          }],
        status: [
          {
            required: true,
            message: '请选择状态',
            trigger: 'change'
          }],
        uid: [{
          required: true,
          message: '请选择活动区域',
          trigger: 'change'
        }],
        IP: [{
          required: true,
          message: '请输入IP',
          trigger: 'blur'
        }],
        industry: [{
          required: true,
          message: '请输入所属行业',
          trigger: 'blur'
        }],
      }
    }
  },
  created() {
    // vosRateList: [], //比例（取值0-100，数字）
    //     vosChannelList: [], //声道 （-1是全部 0-只质检被叫声道 1-只质检主叫声道，下拉）
    //     vosShortList: [], //时长 （低于N秒不质检，数字0-60）
    for (let i = 0; i < 101; i++) {
      this.vosRateList.push({
        value: i + '',
        label: i + '',
      })
    }
    for (let i = 0; i < 61; i++) {
      this.vosShortList.push({
        value: i + '',
        label: i + '',
      })
    }

 this.vosShortListtime.forEach(item=>{

      this.vosShortList.push({
        value: item.value + '',
        label: item.label,
      })
 })
    this.getOptionKeyValueFun()
    this.getVosRate()
    this.getVosChannel()
    this.getVosShort()
    this.getFirstData(this.searchForm.firstForm)
    this.getUserList()


    // vosRate:null,
    //     vosChannel:-1,
    //     vosShort:null,
  },
  watch: {},
  mounted() {

  },
  methods: {
    batchOpenCaller() {
      if (this.selectIds.length == 0) {
        ElMessage({
          type: 'info',
          message: '请先勾选列表'
        })
        return
      }
      this.batchOpenCallerLoading = true
      voiceCheck.batchSwitch({
        "ids": this.selectIds,
        "status": 0
      }).then(res => {
        let {code} = res
        if (code == 200) {
          ElMessage({
            type: 'success',
            message: '操作成功'
          })
          this.getIndustryList(this.searchForm.secondForm)
        }
      }).finally(() => {
        this.batchOpenCallerLoading = false
      })
    },
    batchCloseCaller() {
      if (this.selectIds.length == 0) {
        ElMessage({
          type: 'info',
          message: '请先勾选列表'
        })
        return
      }
      this.batchCloseCallerLoading = true
      voiceCheck.batchSwitch({
        "ids": this.selectIds,
        "status": 1
      }).then(res => {
        let {code} = res
        if (code == 200) {
          ElMessage({
            type: 'success',
            message: '操作成功'
          })
          this.getIndustryList(this.searchForm.secondForm)
        }
      }).finally(() => {
        this.batchCloseCallerLoading = false
      })
    },
    handleSelectionChange(item) {
      this.selectIds = item.map(item => item.id)
    },
    callerStart() {
      this.callerStartLoading = true
      voiceCheck.callerStart({id: this.searchForm.secondForm.clientId}).then((res) => {
        if (res.code === 200) {

          this.$message.success('操作成功!')
          this.getIndustryList(this.searchForm.secondForm)
        } else {
          this.$message.warning(res.msg || res.message)
        }
      }).catch(res => {
        this.$message.error(res.msg || res.message)
      }).finally(() => {
        this.callerStartLoading = false
      })

    },
    callerClose() {
      this.callerCloseLoading = true
      voiceCheck.callerClose({id: this.searchForm.secondForm.clientId}).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功!')
          this.getIndustryList(this.searchForm.secondForm)
        } else {
          this.$message.warning(res.msg || res.message)
        }
      }).catch(res => {
        this.$message.error(res.msg || res.message)
      }).finally(() => {
        this.callerCloseLoading = false
      })
    },
    // 获取总开关状态
    getOptionKeyValueFun() {
      voiceCheck.getOptionKeyValue({
        "optionType": "voiceCheck",
        "optionKey": "vosEnable"
      }).then((res) => {
        this.form = res.data
      })
    },
    //
    getVosRate() {
      // vosRateForm:{},
      // vosChannelForm:{},
      // vosShortForm:{},
      voiceCheck.getOptionKeyValue({
        "optionType": "voiceCheck",
        "optionKey": "vosRate"
      }).then((res) => {
        this.vosRateForm = res.data || {}
      })
    }, getVosChannel() {
      voiceCheck.getOptionKeyValue({
        "optionType": "voiceCheck",
        "optionKey": "vosChannel"
      }).then((res) => {
        this.vosChannelForm = res.data || {}
      })
    }, getVosShort() {
      voiceCheck.getOptionKeyValue({
        "optionType": "voiceCheck",
        "optionKey": "vosShort"
      }).then((res) => {
        this.vosShortForm = res.data || {}
      })
    },
    // 获取主表数据
    getFirstData(form) {
      this.loadingFirst = true
      voiceCheck.getFirstList(form).then((res) => {
        this.tableData.firstTableData = res.data
        console.log(this.tableData.firstTableData);
        this.tableData.firstTableData.records.forEach((item, i) => {
          item.onLine = this.timeToNumber(item.heartTime)
          // if (this.tableData.secondTableData.records.length == 0 ) {
          this.searchForm.secondForm.uid = this.tableData.firstTableData.records[0].uid
          this.searchForm.secondForm.clientId = this.tableData.firstTableData.records[0].id
          this.getIndustryList(this.searchForm.secondForm)
          // }
        })
        if (this.tableData.firstTableData.records.length == 0) {
          this.tableData.secondTableData.records = []
          this.tableData.secondTableData.total = 0
        }
        this.loadingFirst = false
      })
    },
    // 获取子表数据
    getIndustryList(form) {
      if (this.searchForm.secondForm.uid != undefined && this.searchForm.secondForm.uid != null) {
        this.loadingSecond = true
        voiceCheck.industryList(form).then((res) => {
          this.tableData.secondTableData = res.data;
          this.loadingSecond = false
          this.tableData.secondTableData?.records?.forEach(item => {
            if (item.telAPrefix == 0) {
              item.telAPrefix = '';
            }
            if (item.telBPrefix == 0) {
              item.telBPrefix = '';
            }
          })
        })
      }
    },
    // 子表查询
    secondSearch() {
      this.searchForm.secondForm.createTimeArr == null ? this.searchForm.secondForm.createTimeArr = [] : ''
      this.searchForm.secondForm.createTimeBegin = this.searchForm.secondForm.createTimeArr[0]
      this.searchForm.secondForm.createTimeEnd = this.searchForm.secondForm.createTimeArr[1]
      this.getIndustryList(this.searchForm.secondForm)
    },
    // 获取用户数据
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
    },
    // 是否在线
    timeToNumber(time) {
      if (time !== null) {
        let sendtime = new Date(time).getTime();
        let timestamp = Date.parse(new Date());
        if (timestamp - sendtime > 600000) { //超过十分钟
          return 0
        } else {
          return 1
        }
      } else {
        return 0
      }
    },
    // 新增用户
    addUser() {
      this.dialogTitle = '新增用户'
      this.dialogVisible = true
      this.ruleForm1 = {}
      this.ruleForm2 = {}
    },
    setup(e) {
      this.dialogTitle = '质检时长设置'
      this.dialogVisible = true
      this.ruleForm3.interceptDuration = e.interceptDuration
      this.ruleForm3.id = e.id
      this.ruleForm3.ip = e.ip
    },
    // 新增配置
    addConfig() {
      this.dialogTitle = '新增配置'
      this.dialogVisible = true
      this.ruleForm1 = {}
      this.ruleForm2 = {}
    },
    // 修改配置
    editorConfig(row) {
      this.dialogTitle = '编辑配置'
      this.ruleForm2 = row
      this.dialogVisible = true
    },
    // 修改概率
    upText(row) {
      voiceCheck.editIndustry(row).then((res) => {
        ElMessage({
          type: 'success',
          message: '修改成功!'
        });
      })
    },
    // 点击主表单行
    firstRowClick(record, index) {
      console.log(record, index);
      this.searchForm.secondForm.uid = record.uid
      this.searchForm.secondForm.clientId = record.id
      this.getIndustryList(this.searchForm.secondForm)
    },
    changeVosRate() {
      this.$confirm('将修改状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        voiceCheck.editOptionKeyValue(this.vosRateForm).then((res) => {
          ElMessage({
            type: 'success',
            message: '修改成功!'
          });
        })
      }).catch(() => {
        this.getVosRate()
        ElMessage({
          type: 'info',
          message: '已取消'
        });
      });
    },
    changeVosChannel() {
      this.$confirm('将修改状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        voiceCheck.editOptionKeyValue(this.vosChannelForm).then((res) => {
          ElMessage({
            type: 'success',
            message: '修改成功!'
          });
        })
      }).catch(() => {
        this.getVosChannel()
        ElMessage({
          type: 'info',
          message: '已取消'
        });
      });
    },
    changeVosShort() {
      this.$confirm('将修改状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        voiceCheck.editOptionKeyValue(this.vosShortForm).then((res) => {
          ElMessage({
            type: 'success',
            message: '修改成功!'
          });
        })
      }).catch(() => {
        this.getVosShort()
        ElMessage({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 确认修改
    confirm(type, row) {
      this.$confirm('将修改按钮状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (type == 1) {
          voiceCheck.editOptionKeyValue(this.form).then((res) => {
            ElMessage({
              type: 'success',
              message: '修改成功!'
            });
          })
        } else if (type == 2) {
          voiceCheck.editUserData(row).then((res) => {
            ElMessage({
              type: 'success',
              message: '修改成功!'
            });
          })
        } else if (type == 3) {
          voiceCheck.editIndustry(row).then((res) => {
            ElMessage({
              type: 'success',
              message: '修改成功!'
            });
          })
        } else {
          this.getOptionKeyValueFun()
          this.getFirstData(this.searchForm.firstForm)
          this.getIndustryList(this.searchForm.secondForm)
        }

      }).catch(() => {
        this.getOptionKeyValueFun()
        this.getFirstData(this.searchForm.firstForm)
        this.getIndustryList(this.searchForm.secondForm)
        ElMessage({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 删除
    deleteConfig(row) {
      this.$confirm('将删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        voiceCheck.deleteIndustry({
          id: row.id
        }).then((res) => {
          this.getIndustryList(this.searchForm.secondForm)
          ElMessage({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
        ElMessage({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 分页
    handleSizeChange_first(val) {
      // console.log(`每页 ${val} 条`);
      this.searchForm.firstForm.pageSize = val
      this.getFirstData(this.searchForm.firstForm)
    },
    handleCurrentChange_first(val) {
      // console.log(`当前页: ${val}`);
      this.searchForm.firstForm.page = val
      this.currentPage1 = val
      this.getFirstData(this.searchForm.firstForm)
    },
    handleSizeChange(val) {
      this.searchForm.secondForm.pageSize = val
      this.getIndustryList(this.searchForm.secondForm)
    },
    handleCurrentChange(val) {
      this.searchForm.secondForm.page = val
      this.currentPage2 = val
      this.getIndustryList(this.searchForm.secondForm)
    },
    // 校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //主被叫前缀为空的时候，给0
          if (EmptyUtil.isEmpty(this.ruleForm2.telAPrefix)) {
            this.ruleForm2.telAPrefix = '0';
          }
          if (EmptyUtil.isEmpty(this.ruleForm2.telBPrefix)) {
            this.ruleForm2.telBPrefix = '0';
          }
          if (this.dialogTitle == '新增用户') {
            this.addUserData = true
            voiceCheck.addUserData(this.ruleForm1).then((res) => {
              this.addUserData = false
              ElMessage({
                message: '操作成功！',
                type: 'success',
              })
              this.getFirstData(this.searchForm.firstForm)
              this.dialogVisible = false
            })
          }

          if (this.dialogTitle == '质检时长设置') {
            let seconds = this.ruleForm3.interceptDuration
            if (Number(seconds) < 60) {
              ElMessage({
                message: '质检时长必须大于60s',
                type: 'info',
              })
              return
            }
            this.addUserData = true
            voiceCheck.setUP({
                  interceptDuration: seconds,
                  id: this.ruleForm3.id,
                  ip: this.ruleForm3.ip
                }
            ).then((res) => {
              this.addUserData = false
              ElMessage({
                message: '操作成功！',
                type: 'success',
              })
              this.getFirstData(this.searchForm.firstForm)
              this.dialogVisible = false
            })
          }

          if (this.dialogTitle == '新增配置') {
            this.addUserData = true
            if (!this.ruleForm2.uid) {
              // 用户添加配置时，uid固定为用户自己的用户ID
              this.ruleForm2.uid = this.$store.getters.user.uid
            }
            this.ruleForm2.clientId = this.searchForm.secondForm.clientId
            voiceCheck.addIndustry(this.ruleForm2).then((res) => {
              this.addUserData = false
              ElMessage({
                message: '操作成功！',
                type: 'success',
              })
              this.getIndustryList(this.searchForm.secondForm)
              this.dialogVisible = false
            })
          }
          if (this.dialogTitle == '编辑配置') { //editIndustry
            this.addUserData = true
            this.ruleForm2.clientId = this.searchForm.secondForm.clientId
            voiceCheck.editIndustry(this.ruleForm2).then((res) => {
              this.addUserData = false
              ElMessage({
                message: '操作成功！',
                type: 'success',
              })
              this.getIndustryList(this.searchForm.secondForm)
              this.dialogVisible = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.xiugai {
  color: #409EFF;
}

.pageTop {
  display: flex;
}

.first-table {
  width: 42vw;
  border-right: 1px solid #E4E7ED;
  padding: 15px;
  height: 100%;
}

.second-table {
  width: 58vw;
  padding: 15px;
  height: 100%;

  &_top {
    align-items: center;
    margin-left: 10px;
    display: flex;

    &_button {
      margin-left: 20px;
    }

    margin-bottom: 20px;
  }
}

.div-width {
  width: 90px;
}

.line-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.online-icon {
  background: #67C23A;
}

.offline-icon {
  background: #909399;
}

.proportion {
  padding: 5px 8px;
  background: #efefef;
}

.button {
  margin-right: 10px;
  color: #409EFF;
  cursor: pointer;
}

.open-font {
  color: #13ce66;
  margin-left: 5px;
}

.close-font {
  color: #ff4949;
  margin-left: 5px;
}
</style>