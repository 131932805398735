// 语音质检-语音类型
import { Request } from "@/http/request"

// 总开关状态查询
export function getOptionKeyValue (parameter: any){
    return Request.axiosInstance.post('/web/user-options/get-option-key-value', parameter)
}

// 总开关状态编辑
export function editOptionKeyValue (parameter: any){
    return Request.axiosInstance.post('/web/user-options/edit', parameter)
}

// 心跳配置列表查询
export function getFirstList (parameter: any){
  return Request.axiosInstance.post('/open/voice-client-heart-config/list', parameter)
}

// 心跳配置添加
export function addUserData (parameter: any){
    return Request.axiosInstance.post('/open/voice-client-heart-config/add', parameter)
}

// 心跳配置编辑接口
export function editUserData (parameter: any){
    return Request.axiosInstance.post('/open/voice-client-heart-config/edit', parameter)
}

// 行业配置查询接口
export function industryList (parameter: any){
    return Request.axiosInstance.post('/open/voice-vos-user-industry/list', parameter)
}

//添加
export function addIndustry(parameter: any){
    return Request.axiosInstance.post('/open/voice-vos-user-industry/add', parameter)
}

// 编辑 
export function editIndustry(parameter: any){
    return Request.axiosInstance.post('/open/voice-vos-user-industry/edit', parameter)
}

// 删除
export function deleteIndustry(parameter: any){
    return Request.axiosInstance.post('/open/voice-vos-user-industry/delete', parameter)
}

// 主叫质检批量开启
export function callerStart(parameter: any){
    return Request.axiosInstance.post('/open/voice-vos-user-industry/enable-all', parameter)
}

// 主叫质检批量关闭
export function callerClose(parameter: any){
    return Request.axiosInstance.post('/open/voice-vos-user-industry/disable-all', parameter)
}

//设置质检时常
export function setUP(parameter: any){
  return Request.axiosInstance.post('/open/voice-client-heart-config/edit-intercept-duration', parameter)
}

//设置质检时常
export function batchSwitch(parameter: any){
    return Request.axiosInstance.post('/open/voice-vos-user-industry/batch-switch', parameter)
  }

export default {batchSwitch,setUP,callerStart,callerClose, getOptionKeyValue, editOptionKeyValue, getFirstList, addUserData, editUserData, industryList, addIndustry, editIndustry, deleteIndustry }